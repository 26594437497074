import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[ssToggleSideSubmenu]'
})
export class ToggleSideSubmenuDirective {
  constructor(
    private el: ElementRef,
    private render: Renderer2
  ) {}

  @HostListener('click')
  public onMouseClick(): void {
    const submenu = this.el.nativeElement.querySelector('.ss-side-submenu');

    if (!submenu) { return; }

    if (submenu.classList.contains('is-open')) {
      this.render.removeClass(submenu, 'is-open');
      return;
    }

    const list = document.querySelectorAll('.ss-side-submenu');
    for (let i = 0; i < list.length; i++) {
      list[i].classList.remove('is-open');
    }

    this.render.addClass(submenu, 'is-open');
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.el.nativeElement.contains(targetElement)) {
      const submenu = this.el.nativeElement.querySelector('.ss-side-submenu');
      if (submenu.classList.contains('is-open')) {
        this.render.removeClass(submenu, 'is-open');
        return;
      }
    }
  }
}
