import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
// third party
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask/src/currency-mask.config';
import {CustomCurrencyMaskConfig} from './shared/components-config/currency-mask/custom-currency-mask-config';
// first party
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {RequestInterceptor} from './core/auth/request.interceptor';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {CustomEncodeHttpParamsInterceptor} from './shared/components-config/custom-encode-http-params';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    SnotifyModule,
    AppRoutingModule,
    CoreModule,
    DashboardModule
  ],
  exports: [],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {provide: HTTP_INTERCEPTORS, useClass: CustomEncodeHttpParamsInterceptor, multi: true},
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
