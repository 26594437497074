import {Component, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {HeaderService} from './header.service';

@Component({
  selector: 'ss-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  userInitials: string;
  userImage: string;
  userOptions = true;

  constructor(
    private render: Renderer2,
    private authService: AuthService,
    private headerService: HeaderService) {}

  ngOnInit() {
    this.getUserData();

    this.render.listen('document', 'click', (event) => {
      if (event.target.closest('#userOptions')) {
        return;
      }

      if (!this.userOptions) {
        this.toggleUserOptions();
      }
    });
  }

  toggleUserOptions() {
    this.userOptions = !this.userOptions;
  }

  logout() {
    this.authService.signOut();
  }

  private getUserData() {
    this.headerService.getUserData().subscribe((response: any) => {
      this.username = response.username;
      this.userImage = response.avatar;
      this.userInitials = response.avatar ? '&nbsp;' : this.getUserInitials(response.username);

      localStorage.setItem('userRoles', JSON.stringify(response.roles));

      if (
        response.roles.length === 1 &&
        response.roles[0].id === 3 &&
        !response.client) {
          this.logout();
      }
    }, (error: any) => {
      console.log(error);

      // if (error.status === 403) this.logout();
    });
  }

  private getUserInitials(name: string): string {
    let initials: any = name.match(/\b\w/g);
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials.toString();
  }

}
