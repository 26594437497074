import {Directive, Renderer2, HostListener} from '@angular/core';

@Directive({
  selector: '[ssShrinkMenu]'
})
export class ShrinkMenuDirective {

  constructor(
    private render: Renderer2) {
  }

  @HostListener('click')
  onMouseClick() {
    const menu = document.querySelector('.ss-side-menu');
    const submenu = document.querySelectorAll('.ss-side-submenu');
    const container = document.querySelector('.ss-main-container');
    const reduceLink = document.querySelector('#shrink-menu');
    const reduceText = document.querySelectorAll('#shrink-menu span')[1];

    if (menu.classList.contains('is-reduced')) {
      this.render.removeClass(menu, 'is-reduced');
      this.render.removeClass(container, 'menu-is-reduced');
      this.render.removeClass(reduceLink, 'is-reduced');

      reduceText.innerHTML = 'reduzir';

      for (let i = 0; i < submenu.length; i++) {
        this.render.removeClass(submenu[i], 'is-reduced');
      }
      return;
    }

    this.render.addClass(menu, 'is-reduced');
    this.render.addClass(container, 'menu-is-reduced');
    this.render.addClass(reduceLink, 'is-reduced');

    reduceText.innerHTML = 'expandir';

    for (let i = 0; i < submenu.length; i++) {
      this.render.addClass(submenu[i], 'is-reduced');
    }

  }

}
