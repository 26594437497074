import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// first party
import {navItems} from '../../_nav';
import {clientNavItems} from '../../_clientNav';
import {HeaderService} from '../header/header.service';

@Component({
  selector: 'ss-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public navItems = navItems;
  public clientNavItems = clientNavItems;
  public isReduced = false;
  public isOpen = false;
  public isClient = null;
  public permissionList: string[] = [];

  constructor(
    private router: Router,
    private headerService: HeaderService
  ) {}

  ngOnInit() {
    this.headerService.getUserData().subscribe((response: any) => {
      this.permissionList = response.menu_permissions;

      if(response.client){
        this.isClient = response.client ? response.client.id : null;
        this.router.navigate(['main/last-event']);
      }
    });
  }
}
