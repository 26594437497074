import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';

@Directive({
  selector: '[ssCheckMenuPermission]'
})
export class CheckMenuPermissionDirective implements OnInit {
  @Input('ssCheckMenuPermission') permissionName = '';
  @Input() permissionList: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.permissionName != '' && this.permissionList != []) {
      for (let i = 0; i < this.permissionList.length; i++) {
        this.permissionName = this.permissionName.replace('_', '');
        if (this.permissionName.toLowerCase() === this.permissionList[i].toLowerCase()) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          break;
        } else {
          this.viewContainerRef.clear();
        }
      }
    }
  }
}
