export const navItems = [
  {
    name: 'Comercial',
    icon: 'icon-case',
    children: [
      {
        permission: 'CLIENT',
        name: 'Clientes',
        url: 'commercial/clients',
        icon: 'icon-order-person'
      }
      // {
      //   permission: 'ORDER',
      //   name: 'Orçamentos',
      //   url: 'commercial/orders',
      //   icon: 'icon-ticket'
      // },
      // {
      //   permission: 'BUDGET',
      //   name: 'Pedidos',
      //   url: 'commercial/budgets',
      //   icon: 'icon-bill'
      // },
      // {
      //   permission: 'ITEM',
      //   name: 'Produtos e Serviços',
      //   url: 'commercial/items',
      //   icon: 'icon-box'
      // },
      // {
      //   permission: 'REASON',
      //   name: 'Motivos',
      //   url: 'commercial/reasons',
      //   icon: 'icon-note'
      // }
    ]
  }, {
    name: 'Operacional',
    icon: 'icon-operational',
    children: [
      {
        permission: 'MASTEREVENT',
        name: 'Grupos de Evento',
        url: 'operational/events-master',
        icon: 'icon-flag'
      },{
        permission: 'EVENT',
        name: 'Eventos',
        url: 'operational/events',
        icon: 'icon-flag'
      }, {
        permission: 'TECHNICIAN',
        name: 'Técnicos',
        url: 'operational/technicians',
        icon: 'icon-table-bell'
      }, {
        permission: 'PRODUCT',
        name: 'Produtos',
        url: 'operational/products',
        icon: 'icon-hamburger'
      }, {
        permission: 'POINT_OF_SALE',
        name: 'Pontos de Venda',
        url: 'operational/point-of-sales',
        icon: 'icon-money-pin'
      }, {
        permission: 'PAYMENT_TYPE',
        name: 'Formas de Pagamento',
        url: 'operational/payment-types',
        icon: 'icon-credit-card'
      }, {
        permission: 'DEVICE',
        name: 'Dispositivos',
        url: 'operational/devices',
        icon: 'icon-phone'
      }, {
        permission: 'APPLICATION_PERMISSION',
        name: 'Permissões do App',
        url: 'operational/app-permissions',
        icon: 'icon-phone-checked'
      }
    ]
  }, {
    name: 'Administrativo',
    icon: 'icon-tools',
    children: [
      {
        permission: 'PERMISSION',
        name: 'Permissões',
        url: 'administrative/permissions',
        icon: 'icon-permissions'
      }, {
        permission: 'USER',
        name: 'Usuários',
        url: 'administrative/users',
        icon: 'icon-usuario'
      }, {
        permission: 'PENDENCY',
        name: 'Pendências',
        url: 'administrative/pendencies',
        icon: 'icon-warning'
      }, {
        permission: 'NOTIFICATION',
        name: 'Notificações',
        url: 'administrative/notifications',
        icon: 'icon-bell'
      }, {
        permission: 'PARAMETERIZATION',
        name: 'Parametrizações',
        url: 'administrative/parameterization',
        icon: 'icon-operational'
      }
    ]
  }
];
