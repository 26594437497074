import {environment} from '../../environments/environment';
import * as moment from 'moment';

import {ConstantObject} from './interfaces/constant-object';

export class AppConstants {
  public static get maxInteger(): number {
    return Number.MAX_SAFE_INTEGER;
  }

  public static get baseUrl(): string {
    return environment.apiBaseUrl;
  }

  public static get reportsUrl(): string {
    return environment.apiReportsUrl;
  }

  public static get authUrl(): string {
    return environment.authBaseUrl;
  }

  public static get datatablePtBr(): Object {
    return {
      'sEmptyTable': 'Nenhum resultado encontrado',
      'sInfo': 'Exibindo _START_-_END_ de _TOTAL_ resultados',
      'sInfoEmpty': 'Nenhum resultado',
      'sInfoFiltered': '(Filtrados de _MAX_ resultados)',
      'sInfoPostFix': '',
      'sInfoThousands': '.',
      'sLengthMenu': 'Exibir _MENU_ resultados',
      'sLoadingRecords': 'Carregando...',
      'sProcessing': 'Processando...',
      'sZeroRecords': 'Nenhum resultado encontrado',
      'sSearch': 'Pesquisar',
      'oPaginate': {
        'sNext': '<span class="icon-right-open"></span>',
        'sPrevious': '<span class="icon-left-open"></span>',
        'sFirst': 'Primeiro',
        'sLast': 'Último'
      },
      'oAria': {
        'sSortAscending': ': Ordenar colunas de forma ascendente',
        'sSortDescending': ': Ordenar colunas de forma descendente'
      }
    };
  }

  public static get masterTypes(): ConstantObject[] {
    return [{
      name: 'Ticket',
      value: 'ticket'
    }, {
      name: 'Cashless Pré-pago',
      value: 'prepaid_cashless'
    }, {
      name: 'Cashless Pos-pago',
      value: 'pospaid_cashless'
    }];
  }

  public static get deviceTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: ''
    },{
      name: 'Telefone',
      value: 'phone'
    }, {
      name: 'Totem',
      value: 'totem'
    },{
      name: 'POS',
      value: 'pos'
    },{
      name: 'Pinpad',
      value: 'pinpad'
    }, {
      name: 'Outro',
      value: 'other'
    }];
  }

  public static get acquirerTypes(): ConstantObject[] {
    return [{
      name: 'Getnet',
      value: 'getnet'
    },{
      name: 'Pagseguro',
      value: 'pagseguro'
    },{
      name: 'Auttar',
      value: 'auttar'
    }]
  }

  public static get statusTypes(): object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Habilitado',
      value: 'enable'
    }, {
      name: 'Desabilitado',
      value: 'disabled'
    }];
  }

  public static get permissionsTypes(): Array<string> {
    return ['all', 'private', 'public'];
  }

  public static get notificationsTypes(): Array<string> {
    return ['all', 'read', 'unread'];
  }

  public static get eventStatus(): Object[] {
    return [{
      name: 'Todos',
      value: '',
      color: ''
    }, {
      name: 'Pendente',
      value: 'pending',
      color: 'warning-color'
    }, {
      name: 'Pronto para Operação',
      value: 'ready',
      color: 'success-color'
    }, {
      name: 'Desabilitado',
      value: 'disabled',
      color: 'danger-color'
    }, {
      name: 'Realizado',
      value: 'done',
      color: 'info-color'
    }];
  }

  public static get devideStatus(): Object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Habilitado',
      value: 'enabled'
    }, {
      name: 'Desabilitado',
      value: 'disabled'
    }];
  }

  public static get pendenceEntities(): ConstantObject[] {
    return [{
      name: 'Todas',
      value: ''
    }, {
      name: 'Produto',
      value: 'MenuItem'
    }, {
      name: 'Técnico',
      value: 'Technician'
    }, {
      name: 'Ponto de venda',
      value: 'PointOfSale'
    }, {
      name: 'Forma de pagamento',
      value: 'PaymentType'
    }];
  }

  public static get booleanTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: 'all'
    }, {
      name: 'Sim',
      value: 'true'
    }, {
      name: 'Não',
      value: 'false'
    }];
  }

  public static get paymentAcquirerTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Crédito',
      value: 'credit'
    }, {
      name: 'Débito',
      value: 'debit'
    }, {
      name: 'Dinheiro',
      value: 'cash'
    }, {
      name: 'Voucher',
      value: 'voucher'
    }, {
      name: 'Rappi Pay',
      value: 'rappipay'
    }, {
      name: 'Pix',
      value: 'pix'
    }];
  }

  public static get datepickerConfig(): Object{
    return {
      format: 'DD/MM/YYYY',
      locale: moment.locale('pt'),
      disableKeypress: true,
      returnedValueType: 'string'
    }
  }

  public static get deleteNotifyConfig(): Object{
    return {
      titleMaxLength: 150,
      timeout: 0,
      showProgressBar: false,
      closeOnClick: false,
      backdrop: 0.5,
      position: 'centerCenter'
    }
  }

  public static get datetimepickerConfig(): Object{
    return {
      format: 'DD/MM/YYYY - HH:mm',
      locale: moment.locale('pt'),
      showTwentyFourHours: true,
      disableKeypress: true,
      returnedValueType: 'string',
      drops: 'down'
    }
  }

  public static get doughnutConfig(): Object {
    return {
      'responsive': true,
      'maintainAspectRatio': false,
      'legend': {
        'position': 'right',
        'labels': {
          'boxWidth': 15
        }
      },
      'tooltips': {
        'callbacks': {
          'label': function (tooltipItem, data) {
            return data.labels[tooltipItem.index];
          }
        }
      }
    };
  }

  public static get featureTypes(): Object[] {
    return [{
      name: 'Configurar Cardápio',
      value: 'menu_configuration'
    }, {
      name: 'Visualizar Operações',
      value: 'operations_report'
    }, {
      name: 'Relatório Caixa',
      value: 'pos_reports'
    }, {
      name: 'Fechar Caixa',
      value: 'pos_closing'
    }, {
      name: 'Cancelar Sangria',
      value: 'withdrawal_cancellation'
    }, {
      name: 'Sangria',
      value: 'withdrawal'
    }, {
      name: 'Cancelar Devolução',
      value: 'refund_cancellation'
    }, {
      name: 'Devolução',
      value: 'refund'
    }, {
      name: 'Cancelar Venda',
      value: 'ticket_sale_cancellation'
    }, {
      name: 'Reimprimir',
      value: 'reprint'
    }, {
      name: 'Abrir Evento',
      value: 'open_event'
    }];
  }

  public static get operationFilterTypes(): Object[] {
    return [{
      name: 'Todas',
      value: ''
    },{
      name: 'Vendas',
      value: 'ticket_sale'
    }, {
      name: 'Vendas Canceladas',
      value: 'ticket_refund'
    }, {
      name: 'Devoluções',
      value: 'refund'
    }, {
      name: 'Devoluções Canceladas',
      value: 'refund_canceled'
    }, {
      name: 'Vendas Re-impressas',
      value: 'reprint'
    }, {
      name: 'Sangrias',
      value: 'withdrawal'
    }, {
      name: 'Sangrias Canceladas',
      value: 'withdrawal_canceled'
    }];
  }

  public static get operationTypes(): Object[] {
    return [{
      name: 'Venda',
      value: 'ticket_sale'
    },{
      name: 'Devolução',
      value: 'ticket_refund'
    },{
      name: 'Sangria',
      value: 'withdrawal'
    }]
  }

  public static get cashlessOperationTypes(): Object[] {
    return [{
      name: 'Recarga',
      value: 'recharge'
    },{
      name: 'Consumo',
      value: 'consumption'
    },{
      name: 'Ativação de Cartão',
      value: 'card_activation'
    },{
      name: 'Baixas de Cartão',
      value: 'card_return'
    },{
      name: 'Bloqueio',
      value: 'card_block'
    },{
      name: 'Devolução de consumo',
      value: 'consumption_refund'
    },{
      name: 'Devolução de recarga',
      value: 'recharge_refund'
    }]
  }

  public static get clientLegalTypes(): ConstantObject[] {
    return [{
      name: 'Pessoa Física',
      value: 'physical_person'
    },{
      name: 'Pessoa Jurídica',
      value: 'legal_person'
    }]
  }


  public static get occurrenceTypes(): Object[] {
    return [{
      name: 'Cancelamento',
      value: 'cancellation'
    },{
      name: 'Reimpressão',
      value: 'reprint'
    }]
  }

  public static get paymentStatus(): Object[]{
    return [{
      name: 'Autorizado',
      value: 'authorized'
    },{
      name: 'Cancelado',
      value: 'cancelled'
    }]
  }

  public static get cashierTypes(): Object[]{
    return [{
      name: 'Todos os caixas',
      value: ''
    },{
      name: 'Somente produção',
      value: true
    }]
  }

  public static get eventTypes(): Object[] {
    return [{
      name: 'Ficha',
      value: 'ticket'
    }, {
      name: 'NFC Pré-pago',
      value: 'prepaid_cashless'
    }, {
      name: 'NFC Pós-pago',
      value: 'pospaid_cashless'
    }];
  }

  public static get nfcTypes(): Object[] {
    return [{
      name: 'Consumação',
      value: 'consumption'
    }, {
      name: 'Recarga',
      value: 'recharge'
    }]
  }

  public static get ticketTypes(): Object[] {
    return [{
      name: 'Produtos',
      value: 'products'
    }, {
      name: 'Bilheteria',
      value: 'box_office'
    }]
  }

  public static get operationCashlessFilterTypes(): Object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Recargas',
      value: 'recharge'
    }, {
      name: 'Recargas Canceladas',
      value: 'recharge_canceled'
    }, {
      name: 'Devolução de Recargas',
      value: 'recharge_refund'
    }, {
      name: 'Devolução de Recargas Canceladas',
      value: 'recharge_refund_canceled'
    }, {
      name: 'Consumos',
      value: 'consumption'
    }, {
      name: 'Consumos Cancelados',
      value: 'consumption_canceled'
    }, {
      name: 'Devolução de Consumo',
      value: 'consumption_refund'
    }, {
      name: 'Devolução de Consumo Cancelado',
      value: 'consumption_refund_canceled'
    }, {
      name: 'Ativações de Cartão',
      value: 'card_activation'
    }, {
      name: 'Baixas de Cartão',
      value: 'card_return'
    }, {
      name: 'Sangrias',
      value: 'withdrawal'
    }];
  }

  public static get paymentStatuses(): Object[] {
    return [{
      name: 'Autorizado',
      value: 'authorized'
    }, {
      name: 'Cancelado',
      value: 'cancelled'
    }];
  }

  public static get customInfoTypes(): Object[] {
    return [{
      label: 'Número',
      name: 'number',
      value: '0'
    }, {
      label: 'Texto',
      name: 'text',
      value: '1'
    }, {
      label: 'CPF',
      name: 'cpf',
      value: '2'
    }, {
      label: 'CNPJ',
      name: 'cnpj',
      value: '3'
    }, {
      label: 'Telefone',
      name: 'telephone',
      value: '4'
    }];
  }

  public static get acquirers(): Object[] {
    return [{
      name: 'Nenhum',
      value: 'none'
    },{
      name: 'Cielo',
      value: 'cielo'
    },{
      name: 'PagSeguro',
      value: 'pagseguro'
    },{
      name: 'Rappi',
      value: 'rappi'
    },{
      name: 'Getnet',
      value: 'getnet'
    },{
      name: 'Auttar',
      value: 'auttar'
    },{
      name: 'Master',
      value: 'master'
    }]
  }

  public static get primaryCode(): Object[] {
    return [{
      name: 'Débito',
      value: 'debit'
    },{
      name: 'Crédito',
      value: 'credit'
    },{
      name: 'Voucher',
      value: 'voucher'
    },{
      name: 'Dinheiro',
      value: 'cash'
    },{
      name: 'Rappi Pay',
      value: 'rappipay'
    },{
      name: 'Pix',
      value: 'pix'
    }]
  }

  public static get onSaleType(): Object[] {
    return [{
      name: 'Por quantidade',
      value: 1
    },{
      name: 'Valor fixo',
      value: 2
    },{
      name: 'Percentual',
      value: 3
    }]
  }

  public static get environmentParameters(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: 'all_of'
    },{
      name: 'POS',
      value: 'pos'
    },{
      name: 'Web',
      value: 'web'
    },{
      name: 'Totem',
      value: 'totem'
    }]
  }

  public static get discountTypes(): ConstantObject[] {
    return [{
      name: 'Valor fixo',
      value: 'fixed'
    }, {
      name: 'Porcentagem',
      value: 'percent'
    }];
  }

  public static get clientIdModes(): ConstantObject[] {
    return [{
      name: 'Manual',
      value: 'manual'
    }, {
      name: 'Cartão',
      value: 'card'
    }];
  }

  public static get menuItemRestrictions(): Object[] {
    return [{
      name: 'Proibida para menores',
      value: 'underage_forbidden'
    }];
  }
}
