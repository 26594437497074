export const clientNavItems = [
  {
    permission: 'LAST_EVENT',
    name: 'Meu Último Evento',
    url: 'last-event',
    icon: 'icon-case'
  },{
    permission: 'MY_EVENTS',
    name: 'Meus Eventos',
    url: 'my-events',
    icon: 'icon-operational'
  },{
    permission: 'EVENT_MASTER',
    name: 'Meus Grupos',
    url: 'my-groups',
    icon: 'icon-flag'
  },{
    permission: 'REPORTS',
    name: 'Relatórios',
    url: 'reports',
    icon: 'icon-tools'
  }
];
