import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { HeaderService } from '../header.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserNotification } from 'src/app/shared/interfaces/userNotification';

@Component({
  selector: 'ss-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() username: string;
  isLoading: boolean;
  isShown = false;
  totalUnread$: Observable<any>;
  notifications$: Observable<UserNotification[]>;

  notifications: UserNotification[] = [];

  hasUnread: boolean;
  hasMore: boolean;

  constructor(
    private headerService: HeaderService,
    private render: Renderer2) { }

  ngOnInit() {
    this.totalUnread$ = this.headerService.getTotalUnreadNotification();

    this.render.listen('document', 'click', (event) => {
      if (event.target.closest('#notifications') || event.target.closest('#notificationList')) { return; }

      if (this.isShown) {
        this.toggleNotifications();
      }
    });
  }

  toggleNotifications() {
    this.getNotifications();
    this.isShown = !this.isShown;
    this.isLoading = true;
    this.notifications = [];
  }

  getNotifications() {
    const params = {};
    params['ordering'] = 'created_at:desc';
    params['by_user'] = this.username;
    this.headerService.getNotifications(params).subscribe((response: any) => {
      this.notifications = response.content;
      this.isLoading = false;
      response.total_pages > 1 ? this.hasMore = true : this.hasMore = false;
    }, (error: any) => {
      console.log(error);
    });
  }

  setWasRead(id: number, wasRead: boolean, position: number) {
    if (wasRead) { return; }

    this.headerService.readNotification(id).subscribe(() => {
      this.notifications[position]['was_read'] = true;
      this.totalUnread$ = this.headerService.getTotalUnreadNotification();
    }, (error: any) => {
      console.log(error);
    });
  }

  setAllread() {
    this.headerService.readAllNotifications().subscribe((response: any) => {
      this.notifications.map(notification => notification['was_read'] = true);
      this.totalUnread$ = this.headerService.getTotalUnreadNotification();
    }, (error: any) => {
      console.log(error);
    });
  }
}
